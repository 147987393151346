<template>
	<div>
		<myheader v-on:parentshow='getglist'></myheader>
		<div class="index">
			<div class="search">

				<div class="trivalwrite">
					<div class="chose">
						<div :class="{'active':sign==1}" @click="getglist('')">产品</div>

					</div>
				</div>
				<span v-if="banner!=1">搜索内容：{{keyword}}</span>

				<div class="travellist" v-if="sign==1">
					<div class="nothing" v-if="total==0">
							<img src="../assets/other/nothing.png" alt="">
							<p>暂未搜索到‘ {{keyword}} ’</p>
						</div>
					<div class="tourism" v-for="(item,i) in glist" :key="i" @click="todetails(item.id)">
					<div class="img">
						<img :src="item.goods_pic" alt="" class="image">
						<div class="day">
							<p class="d1"><span>{{item.t_days}}</span> 日</p>
							<p class="d2">行程天数</p>
						</div>
						<div class="adress">{{item.origin_name}}出发</div>
						<div class="join">{{item.porder_count}}人已参加</div>
					</div>
					<div class="content">
						<h3 class="title">{{item.goods_name}}</h3>
						<div class="label">
							<ul>
								<li v-for="(it,j) in item.tags" :key="j">{{it}}</li>
							</ul>
						</div>
						<div class="flex-between">
							<div class="time">
								<div class="date-label"></div>
								<div v-for="(tuan,j) in item.goods_price.slice(0,5)" :key="j" class="tt">
									<p class="item-date">{{tuan.buy_date}}</p>
									<p v-if="tuan.is_team==1" class="item-status orange">已成团</p>
									<p v-else-if="tuan.remain_number === 0 && tuan.remain_alternate_number === 0" class="item-status orange">已满团</p>
									<!-- <p v-if="tuan.remain_number > 0" class="item-status orange">余位{{tuan.remain_number}}人</p> -->
									<!-- <p v-if="tuan.is_alternate === 1 && tuan.remain_number === 0 && tuan.remain_alternate_number > 0" class="item-status orange">候补{{tuan.remain_alternate_number}}人</p> -->
									<p class="item-status" v-else>可报名</p>
								</div>


							</div>
							<div class="price-item" v-if="item.price_type === 2">
								<span class="label">预售定金</span>
								<span class="val">{{item.common_price}}</span>
								<span class="val2">￥{{item.price}}</span>
							</div>
							<div class="price-item" v-else-if="item.price_type == 1">
								<span class="label">限时特价</span>
								<span class="val">{{item.common_price}}</span>
								<span class="val2">￥{{item.price}}</span>
							</div>
							<p v-else class="p"><span>¥{{item.price | priceFilter}}</span> 起</p>
						</div>

					</div>
				</div>

				</div>

				<!-- yj -->
				<div class="youji" v-if="sign==2">
						<div class="nothing" v-if="total==0">
							<img src="../assets/other/nothing.png" alt="">
							<p>暂未搜索到‘ {{keyword}} ’</p>
						</div>
					<div class="tourism" v-for="(item,i) in tlist" :key="i" @click="todetails2(item.id)">
						<div class="img">
							<img :src="item.attachment" alt="" class="image">
						</div>
						<div class="content">
							<h3>{{item.title}}</h3>

							<div class="time">
								<div>
									<img :src="item.avatar" class="img">
									<p style="color: #FF7C00;">{{item.account}}</p>
								</div>
								 <div>
									<img src="../assets/shot/read.png" alt="">
									<p>{{item.look_number}}人阅读</p>
								</div>
								<!--<div>
									<img src="../assets/shot/read.png" alt="">
									<p>102人收藏</p>
								</div> -->

							</div>

						</div>
					</div>
					<!-- 分页 -->
					<!-- <div class="page">
						<img src="../assets/other/pleft.png" alt="">
						<p>1/1</p>
						<img src="../assets/other/pright.png" alt="">
					</div> -->
				</div>

				<!-- /yj -->
			 <div class="page">
			  <el-pagination
				layout="prev, pager, next"
				:total="total"
				@current-change="handleCurrentChange"
				:page-size="pageSize"
                :hide-on-single-page="total<=pageSize">
				</el-pagination>
		  </div>
			</div>
		</div>

		<myfooter></myfooter>
		<myright></myright>
	</div>

</template>

<script>
import myheader from '../components/header.vue'
import myfooter from '../components/footer.vue'
import myright from '../components/right.vue'
import {searchuTakeAPI,searchGoodsAPI} from "@/api/search"
export default {

  components: {
    myheader, myfooter, myright
  },
  data () {
    return {
     	 keyword: '',
	  	station:"",
      	sign: 1,
	  	tlist:[],
	 	 glist:[],
	  	total:1,
		banner:0,
		pageSize:10,
		page:1

    }
  },
  created () {
    const option = this.$route.query
	console.log(option)
    this.keyword = option.content
	 this.station = option.station
	 this.banner=option.banner
    this.getglist(this.keyword , this.station)
  },
  methods: {
	  gettlist(){

		  this.sign=2
			searchuTakeAPI({content:this.keyword}).then(res=>{
				this.tlist=res.cateList
				this.total=res.rowsCount
			})
	  },
	  todetails(id){
		  	this.$router.push({ path: '/details',query:{id} })
	  },
	  todetails2(id){
		  	this.$router.push({ path: '/imginfo',query:{id} })
			  console.log(id)
	  },
	  handleCurrentChange(currentPage){
		 this.page=currentPage
         this.getglist(this.keyword,this.station)
	  },
	  getglist(key,city){
		  	  this.sign=1
				console.log(11,key)
				if (key!='') {
					this.keyword=key
					this.station=city
				}
		   this.banner=this.$route.query.banner
		   var data={
			content:this.keyword,
			station:this.station,
			 page:this.page,
            pageSize:this.pageSize,
		   }
			searchGoodsAPI(data).then(res=>{
				this.glist=res.cateList
				this.total=res.rowsCount

		  	})
	  }

  }
}
</script>

<style lang="scss" scoped="scoped">
.index{
		min-width: 1200px;
		width: 100%;
		// margin: 0 auto;
		padding-top: 10px;
		background-color: #f9f9f9;

	}
.search{
	width: 1200px;
	min-height:300px;
	margin: 0 auto;
}
.nothing{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	width: 200px;
	// background-color: red;
	margin: 150px auto;
	p{
		margin-top: 10px;
		line-height: 28px;
	}
}
.trivalwrite{
	display: flex;
	justify-content: space-between;
	.chose{
		display: flex;
		div{
			cursor: pointer;
			width: 100px;
			height: 45px;
			font-size: 16px;
			font-weight: 500;

			line-height: 45px;
			text-align: center;
			color: #666666;
			border-bottom: 2px solid #cccccc;
		}
		.active{
			border-bottom: 2px solid #FF7C00;
			color: #ff7c00;
		}

	}
}

.travellist{
	background-color: #fff;
	border-radius: 10px;
	min-height: 500px;
	margin-top: 20px;
	padding: 20px 20px;
	box-sizing: border-box;
	.tourism{
		display: flex;
		margin-bottom: 20px;
		.img{
			position: relative;
			border-radius: 5px;
			width: 330px;
			height: 160px;
			overflow: hidden;
			.image{
				width: 100%;
				min-height: 160px;
				border-radius: 5px 5px 0 0;
			}
			.day{
				position: absolute;
				top: -1px;
				left: 0;
				width: 55px;
				background:rgba(0,0,0,0.4) url(../assets/index/day.png) no-repeat;
				background-size: 100%;
				border-radius: 5px 0 0 0 ;
				p{
					color: #fff;
					text-align: center;
					font-size: 12px;
					line-height: 35px;
					span{
						color: #fff;
						font-weight: 500;
						font-size: 17px;
					}
				}
				.d2{
					line-height: 24px;
					font-size: 11px;
				}
			}

			.adress{
				position: absolute;
				bottom: 5px;
				left: 5px;
				padding: 5px 8px;
				font-size: 11px;
				color: #fff;
				background-color: rgba(0,0,0,0.4);
				border-radius:3px;
			}
			.join{
				position: absolute;
				bottom: 5px;
				right: 5px;
				padding: 5px 8px;
				font-size: 11px;
				color: #fff;
				background-color: rgba(0,0,0,0.4);
				border-radius:3px;
			}
		}

		.content{
			flex: 1;
			padding-left: 20px;
			h3{
				font-size: 17px;
				font-weight: 600;
				line-height: 28px;
				height: 55px;
				// background-color: red;
			}
			.label{
				ul{
					display: flex;
					margin-top: 12px;
					li{
						border: 1px solid #ff7c00;
						border-radius: 2px;
						font-size: 12px;
						padding: 0 10px;
						color: #ff7c00;
						line-height: 23px;
						margin-right:15px ;
					}
				}
			}
			.flex-between{
				margin-top: 15px;
				.p{
					text-align: right;
					padding-right: 20px;
					width: 130px;
					color: #ff4c12;
					font-size: 12px;
					margin-top: 5px;
					span{
						font-size: 22px;
						font-weight: 600;
						text-align: left;
						color: #ff4c12;
					}
				}
				.price-item {
					.label {
						font-weight: 500;
						font-size: 12px;
						color: #FA4C27;
						line-height: 17px;
					}
					.val {
						font-weight: bold;
						font-size: 20px;
						color: #FA4C27;
						line-height: 23px;
						margin-left: 6px;
						&::before {
							content: '￥';
							font-size: 10px;
						}
					}
					.val2 {
						font-weight: 500;
						font-size: 12px;
						color: #808080;
						line-height: 15px;
						margin-left: 4px;
					}
				}
				.time{
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					.date-label {
						width: 35px;
						height: 38px;
						background-repeat: no-repeat;
						background-size: 35px 38px;
						background-image: url('../assets/date-label.png');
					}
					.tt{
						padding: 8px;
						margin-left: 8px;
						text-align: center;
						background: #F7F8FA;
						border-radius: 8px 8px 8px 8px;
						p{
							font-size: 13px;
							line-height: 20px;
							color: #666;
							line-height: 20px;

						}
						.orange{
							color:#FF4C12
						}
						.item-date {
							font-weight: bold;
							font-size: 16px;
							color: #1A1A1A;
							line-height: 20px;
							text-align: center;
							white-space: nowrap;
						}
						.item-status {
							font-size: 12px;
							color: #FA4C27;
							line-height: 14px;
							text-align: center;
						}
					}
				}
			}

		}
	}
	.page{
		width: 120px;

		display: flex;
		height: 25px;
		margin: 45px auto 15px;
		p{

			margin: 0 8px;
			font-size: 14px;
			text-align: center;
			color: #333333;
			line-height: 25px;
		}
	}
}

.youji{
		background-color: #fff;
		border-radius: 10px;
		width:1200px;
		min-height: 500px;
		margin-top: 20px;
		padding: 20px 20px;
		box-sizing: border-box;
		.tourism{
			display: flex;
			margin-bottom: 20px;
			.img{
				position: relative;
				border-radius: 5px;
				width: 300px;
				height: 150px;
				overflow: hidden;
				.image{
					width: 100%;
					min-height: 160px;
					border-radius: 5px 5px 0 0;
				}

			}

			.content{
				flex: 1;
				padding-left: 20px;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				h3{
					font-size: 17px;
					font-weight: 600;
					line-height: 28px;
					height: 40px;
					line-height: 30px;
					// background-color: red;
				}
				.label{
					font-size: 12px;

					font-weight: 400;
					text-align: left;
					color: #333333;
					line-height: 17px;
				}

				.time{

					display: flex;
					>div{
						margin-top: 20px;
						display: flex;
						align-items: center;
						margin-right: 15px;
						img{
							width: 16px;
							height: 17px;
						}
						.img{
							width: 30px;
							height: 30px;
							border-radius: 50%;
						}
						p{
							font-size: 13px;
							margin-left: 5px;
							color: #666;
							line-height: 25px;

						}
						.orange{
							color:#FF4C12
						}
					}
				}

			}
		}

	}
</style>
